import React, { useEffect } from "react"
import { isAndroid, isIOS } from "react-device-detect"
import { navigate } from "gatsby"
import "../styles/scss/index.scss"
import "./index.scss"

export default function QRRedirect({ location }) {
  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get("source") || ""
  const navigateToApp = () => {
    window.location.replace("com.jelurida.bridgechampgame://")

    if (isAndroid) {
      const url = `intent://${window.location.hostname}/#Intent;scheme=${window.location.protocol};package=com.jelurida.bridgechampgame;end`
      setTimeout(() => {
        window.location.replace(url)
      }, 500)
    } else if (isIOS) {
      setTimeout(() => {
        window.location.replace(
          "https://apps.apple.com/app/bridge-champ/id1593602680"
        )
      }, 500)
    } else {
      setTimeout(() => {
        navigate("/")
      }, 500)
    }
  }
  const onMount = async () => {
    try {
      const apiURL = process.env.STRAPI_API_URL || `http://localhost:1337`
      const res = await fetch(`${apiURL}/api/qr-redirects`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            source,
            device: isIOS ? "IOS" : isAndroid ? "ANDROID" : "OTHER",
          },
        }),
      })
      if (!res.ok) {
        throw new Error()
      }
      const body = await res.json()
      if (body.error?.message) {
        throw new Error()
      }
    } catch (err) {
      console.log("server", new Error())
    } finally {
      navigateToApp()
    }
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <section className="hero is-fullheight">
      <div className="hero-body is-justify-content-center">
        <svg className="image is-128x128" viewBox="0 0 400 233" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.466.773l7.85 13.77a94.409 94.409 0 003.845 6.149l-21.329 27.202.106.082c-14.455 17.154-34.429 34.987-56.839 44.772-30.198 13.185-65.625 11.841-91.986 6.4l65.833 111.347.006-.002 8.047 16.276a231.82 231.82 0 00-8.804 3.144l-7.086 2.694-3.859-6.526-81-137L.387 67.324l23.719 8.73c22.845 8.408 66.742 15.064 100.79.198 29.166-12.734 54.885-42.441 65.767-61.682L198.466.773zm-48.779 216.952c14.204-2.525 30.526-4.225 48.81-4.225 37.701 0 70.876 9.053 88.581 16.325l7.068 2.903 4.011-6.503 84.5-137 17.199-27.885-29.03 15.185c-9.667 5.057-25.438 10.063-43.231 11.23-17.736 1.164-36.825-1.524-53.653-11.08-15.848-9-28.994-18.897-39.546-28.548L223.26 62.33c11.399 10.291 25.312 20.638 41.793 29.997 20.671 11.739 43.499 14.718 63.721 13.391a142.547 142.547 0 0024.969-3.901l-66.991 108.612c-20.461-7.344-52.462-14.928-88.255-14.928-21.652 0-40.762 2.246-57.096 5.466l8.286 16.759z"
            fill="url(#prefix__paint0_linear_101_5)"
          />
          <defs>
            <linearGradient
              id="prefix__paint0_linear_101_5"
              x1={51.167}
              y1={-42.181}
              x2={446.75}
              y2={271.048}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E031CA" />
              <stop offset={1} stopColor="#3772FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  )
}

export function Head() {
  return <meta name="robots" content="noindex" />
}
